.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  position: relative;
  min-height: 100vh;
}

.footer {
  background: none;
  font-family: FontAwesome;
  color: #6d6d6d;
  font-size: 15px;
  line-height: 15px;
  height: 45px;
  width: 360px;
  position: absolute;
  bottom: 10px;
  left: 23px;
  z-index: 20;
  transition: all 0.15s linear;
  transition: bottom 0.3s linear;
}


.footerLayer {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 999;
  height: 100px;
}

.footerLayerBg {
  background: rgb(19, 25, 41);
  padding: 1.25em 0;
  box-shadow: 0 0 1em rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: space-evenly; /* Ensure even spacing for the items */
  height: 100%;
  flex-direction: row; /* Arrange items horizontally */
}

.footerItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.footerItem img {
  width: 70px; /* Adjust as needed */
  height: 70px; /* Keep the images uniform */
  object-fit: cover;
  border-radius: 10%; /* Optional: for circular images */
  transition: transform 0.2s;
  cursor: pointer;
}

.footerItem img:hover {
  transform: scale(1.1); /* Add a slight zoom effect on hover */
}

.footerLayerAuthor {
  color: white;
  text-align: center;
  font-size: 0.8em;
  margin-top: 0.5em;
  font-family: "FontAwesome";
  width: -webkit-fill-available;
}

.playlistBtn button {
  --bs-btn-bg: #ffffff00 !important;
  --bs-btn-border-color: #ffffff00 !important;
}

.dropdown-header {
  color: black !important;
}